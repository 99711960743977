<template>
  <div class="begin-task">
    <Countdown status="begin" v-if="data.timeLimitSeconds" :time="data.timeLimitSeconds * 1000" />
    <div class="task-content">
      <div class="begin-title">练习即将开始</div>
      <ol class="begin-ol">
        <li>作业即将开始，请同学确保自己处于不容易受外界干扰的状态下开始作业练习。</li>
        <li>点击“开始练习”按钮后，作业即将开始。中途如有意外情况造成切换出小程序，可通过回到小程序内，点击“做作业”列表中提示“进行中”的作业继续完成。完成练习后请点击“完成作业”提交批改。</li>
        <li>提交后的作业在批改后将显示在“历史作业”列表中，可前往查看作业得分等具体情况。</li>
        <li>本次作业<span class="red">{{data.timeLimitSeconds?'有':'没有'}}</span>完成时间限制。<span class="red">{{data.timeLimitSeconds?'请在时间限制前完成答题并提交，否则可能会造成作业提交不完整影响得分':''}}</span></li>
        <li>本次作业<span class="red">{{data.canRedo?'可以':'不可以'}}</span>在完成后重做。</li>
        <li>本次作业<span class="red">{{data.scoreForAnswer?'有':'没有'}}</span>达标分数限制。<span class="red">{{data.scoreForAnswer?'（在分数达标的情况下可在“历史作业”中查看作业答案详情。）':''}}</span></li>
        <li v-if="data.taskContent">作业要求：{{ data.taskContent }}</li>
      </ol>
      <div class="begin-btn-container">
        <van-button @click="onClickBegin" round type="info" block>开始练习</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import Countdown from "./Countdown";
export default {
  props: {
    data: {
      type: Object,
      default: {},
    },
    onBegin: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    onClickBegin() {
      this.$emit("onBegin");
    },
  },
  components: {
    Countdown,
  },
};
</script>

<style lang="less">
.begin-task {
  .task-content {
    padding: 0.9rem 0.3rem;
  }
  .begin-title {
    font-size: 0.32rem;
    margin-bottom: 0.4rem;
    font-weight: bold;
  }
  .begin-ol {
    color: #8f92a1;
    list-style-type: decimal;
    list-style-position: outside;
    padding-left: 0.3rem;
    li {
      margin-top: 0.1rem;
    }
  }
  .begin-btn-container {
    padding: 0.8rem 0.4rem 0;
  }
}
</style>
