import Vue from 'vue'
import VueRouter from 'vue-router'
import PenAndComp from '../views/PenAndComp.vue'
import studentAudioTask_exam from '../views/studentAudioTask_exam.vue'
Vue.use(VueRouter)

  const routes = [
  {
    // 综合卷
    path: '/pen',
    name: 'pen',
    component: PenAndComp
    },
    {
      // 听说一体卷
      path: '/listenAndSpeak',
      name: 'listenAndSpeak',
      component: studentAudioTask_exam
      },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
