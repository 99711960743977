<template>
  <van-action-sheet :overlay="false" v-model="show">
    <div class="answer-sheet-container">
      <div class="answer-sheet-scroll">
        <div class="answer-sheet-title">答题卡</div>
        <div v-html="data.answerSheet" />
        <div v-if="showBtn" class="btn-container">
          <van-button @click="onSubmit" round type="primary" block>完成练习</van-button>
        </div>
      </div>
    </div>
  </van-action-sheet>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: {},
    },
    answerSheetVH: {
      type: Number,
      default: 50,
    },
    showBtn: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      show: true,
    };
  },
  mounted() {
    this.resize();
  },
  updated() {
    this.resize();
  },
  methods: {
    resize() {
      document.querySelector(
        ".van-action-sheet"
      ).style.height = `${this.answerSheetVH}vh`;
    },
    onSubmit() {
      this.$emit('submit')
    }
  },
  components: {},
};
</script>

<style lang="less">
.answer-sheet-container {
  height: 100%;
  padding: 0.2rem;
  .answer-sheet-title {
    font-size: .32rem;
    font-weight: bold;
    text-align: center;
  }
  .answer-sheet-scroll {
    padding: 0.1rem 0 .4rem;
    height: 100%;
    overflow-y: auto;
  }
  input[type="text"]{
    /*width: 6rem !important;*/
    text-align: left !important;
    padding-left: .3rem;
  }
}
</style>
