<template>
  <div class="countdown">
    <div class="holder"></div>
    <div v-if="status == 'begin'" class="fy-countdown-container">
      <span>限制时间</span>
      <img class="time-icon" src="../assets/icon-1_33.png" />
      <span class="shu">丨</span>
      <div>
        <div>
          <span class="time-text">{{ Math.floor(time / 3600000) }}</span>
          <span class="small">小时</span>
          <span class="time-text">{{ Math.floor(time % 3600000) / 60000 }}</span>
          <span class="small">分钟</span>
        </div>
      </div>
    </div>
    <van-count-down @finish="onCountdownFinish" v-if="status == 'countdown'" :time="time">
      <template #default="timeData">
        <div class="fy-countdown-container">
          <span>剩余时间</span>
          <img class="time-icon" src="../assets/icon-1_33.png" />
          <span class="shu">丨</span>
          <div>
            <span class="time-text">{{ timeData.hours }}</span>
            <span class="small">小时</span>
            <span class="time-text">{{ timeData.minutes }}</span>
            <span class="small">分钟</span>
          </div>
        </div>
      </template>
    </van-count-down>
    <div v-if="status == 'end'" class="fy-countdown-container">
      <span>时间结束，正在提交试卷</span>
    </div>
    <!-- <div class="task-content"  v-html='data.content'></div> -->
  </div>
</template>

<script>
export default {
  name: "Home",
  props: {
    time: {
      type: Number,
    },
    status: {
      type: String,
    },
  },
  data() {
    return {};
  },
  created() {
    if (this.status == 'countdown' && !this.time) {
      this.$emit('onFinish')
    }
    console.log('countdowntime', this.time)
  },
  methods: {
    onCountdownFinish() {
      this.$emit('onFinish')
    },
  },
  components: {},
};
</script>

<style lang="less">
.holder{
  height: 0.6rem;
}
.fy-countdown-container {
  position: fixed;
  top: 0;
  z-index: 10;
  width: 7.5rem;
  background: #ff1717;
  color: #fff;
  font-size: 0.3rem;
  height: 0.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  .shu {
    margin: 0 0.2rem;
  }
  .time-text {
    font-size: 0.34rem;
  }
  .small {
    font-size: 0.22rem;
    margin: 0 0.02rem;
  }
  .time-icon {
    width: 0.16rem;
    height: 0.23rem;
    margin-left: 0.12rem;
  }
}
</style>
