<template>
  <div class="bottom">
    <!-- <van-loading v-show="isShow" size="24px" color="#1989fa" vertical
      >音频上传中...</van-loading
    > -->
    <audio
      id="audio"
      ref="audio"
      style="display: block !important;"
      autoplay
      preload="auto"
    ></audio>
    <p id="pross" class="pross">
      <span class="p-span" :style="{ width: rate }"></span>
    </p>
    <div class="one" v-if="status === 1">
      <span class="label">{{
        !isNaN(duration)
          ? `音频时长:${currentTime}/${duration}秒,音频数量:${currentNumner}/${questionAudioNumber}`
          : "音频加载中......."
      }}</span>
      <van-button
        id="playBtn"
        style="margin-left: 10px"
        v-if="showPop"
        type="primary"
        @click="
          showPop = false;
          $refs.audio.play();
        "
        >播放</van-button
      >
      <van-button
        type="primary"
        v-if="isRedo || practiceMode == 1"
        @click="stopAudio"
        style="margin-left: 10px"
        >跳过</van-button
      >
    </div>
    <div class="two" v-if="status === 2 || status === 3">
      <span class="label"
        >{{ status === 2 ? "等待" : "准备" }}中......<span
          class="label"
          style="color: red"
          >{{ second }}</span
        >秒</span
      >
      <!-- <van-button type="primary" @click="handleSkip" style="margin-left: 10px"
        >跳过</van-button
      > -->
    </div>
    <div
      class="two"
      v-if="status === 5 || status === 6 || status === 7 || status === 8"
    >
      <span class="label"
        >{{
          status === 5
            ? "答题"
            : status === 6
            ? "准备"
            : status === 7
            ? "检查"
            : "休息"
        }}倒计时......<span class="label" style="color: red">{{ second }}</span
        >秒</span
      >
    </div>
    <!-- 提交按钮 -->
    <!-- <div v-if="status === 4 && currenttextType === 'bishi'">
      <span class="toptip">点击“提交”，结束本小题</span>
      <van-button
        type="primary"
        :loading="isBtnLoading"
        @click="handleFrontSumbit(true)"
      >
        提交
      </van-button>
    </div> -->
    <div
      class="three flex-layout"
      v-if="status === 4 && currenttextType === 'kouyu'"
    >
      <div style="display: flex; align-items: center" v-if="status2 === true">
        <span class="label">录音中...</span>
        <div class="flex-layout right-recorder" style="margin-left: 10px">
          <!--        <van-button type="primary" icon="el-icon-video-pause" @click="startRecorder">录音</van-button>-->
          <div class="second-div">
            <canvas id="canvas"></canvas>
            <canvas id="line"></canvas>
          </div>
          <!--     <van-button type="danger" icon="el-icon-video-play" @click="playRecorder">播放</van-button>-->
          <van-button type="danger" icon="el-icon-video-pause" @click="recStop"
            >停止</van-button
          >
          <!--<van-button type="success" @click="nextQuestion" style="margin-left: 10px" v-if="sort<exam.flows.length-1">下一题</van-button>-->
        </div>
      </div>
      <div v-if="status2 === false">
        抱歉，试音未能获取到录音权限,不能进行录音！
      </div>
    </div>
    <!-- <van-popup :close-on-click-overlay="false" v-model="showPop">
      <van-button
        type="info"
        @click="
          showPop = false;
          $refs.audio.play();
        "
        >播放声音</van-button
      >
    </van-popup> -->
  </div>
</template>

<script>
import { stringify } from "qs";
import Vue from "vue";
import req from "../util/req";
import { Toast, Popup } from "vant";
Vue.use(Popup);
import Recorder from "js-audio-recorder";
const lamejs = require("lamejs");
const recorder = new Recorder({
  sampleBits: 16, // 采样位数，支持 8 或 16，默认是16
  sampleRate: 16000, // 采样率，支持 11025、16000、22050、24000、44100、48000，根据浏览器默认值，我的chrome是48000
  // sampleRate: 8000,              // 采样率，支持 11025、16000、22050、24000、44100、48000，根据浏览器默认值，我的chrome是48000
  numChannels: 1,
});

// 另换录音插件
import Recorder2 from "recorder-core"; // 引入核心库
//引入mp3格式支持文件；如果需要多个格式支持，把这些格式的编码引擎js文件放到后面统统引入进来即可
import "recorder-core/src/engine/mp3";
import "recorder-core/src/engine/mp3-engine";
//可选的插件支持项，这个是波形可视化插件
import "recorder-core/src/extensions/waveview";
let waitingStart = false,
  timer = null;
let blobResourse = null;
export default {
  name: "Recorder",
  props: {
    exam: {
      type: Object,
      default: function() {
        return {};
      },
    },
    examType: {
      type: String,
      default: "1",
    },
    open: {
      type: Boolean,
      default: false,
    },
    isRedo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      practiceMode: 0,
      showPop: true,
      isShow: false,
      flag: false,
      status: 1,
      second: "", //

      index: -1, //开始步骤

      sort: 0,
      flow: {},

      //波浪图-录音
      drawRecordId: null,
      oCanvas: null,
      ctx: null,
      //波浪图-播放
      drawPlayId: null,
      pCanvas: null,
      pCtx: null,

      Interval: {},
      speakingInterval: {},
      canvasInterval: {},
      questions: [],

      duration: 0,
      currentTime: 0,
      rate: 0,
      questionAudioNumber: 0,
      currentNumner: 0,

      flow: null,
      audioSrc: "",

      status2: "",
      audioMp3Url: "",
      drawStatus: 1,
      recorderStatus: false,
      rec: "", // 录音对象
      recBlob: "", // blob 文件流

      //波浪图-录音
      drawRecordId: null,
      oCanvas: null,
      drawStatus: 1,
      ctx: null,
      currenttextType: "",
      isBtnLoading: false,
    };
  },
  mounted() {
    const self = this;
    this.$refs.audio.addEventListener(
      "ended",
      function() {
        self.handlePlay();
      },
      false
    );
    this.$refs.audio.addEventListener(
      "pause",
      function() {
        self.showPop = true
      },
      false
    );
    document.addEventListener("visibilitychange", function() {
      if (document.visibilityState === "visible") {
        // 在这里执行从后台切回的操作
        this.showPop = true;
        if (this.$refs.audio.src && this.status == 1) {
          this.$refs.audio.play();
        }
      }
    });
  },
  beforeDestroy() {
    // 清除定时器
    clearInterval(this.Interval);
    waitingStart = false;
    clearTimeout(timer);
    timer = null;
    // this.$message.closeAll();
  },
  methods: {
    toClosePlay() {
      if (this.showPop) {
        this.showPop = false;
        this.$refs.audio.play();
      }
    },
    stopAudio() {
      this.showPop = false;
      this.$refs.audio.pause();
      this.$refs.audio.src = "";
      this.currentTime = 0;
      this.duration = 0;
      this.handlePlay();
    },
    init(value, sort, data, practiceMode) {
      console.log(practiceMode);
      this.practiceMode = practiceMode;
      this.index = value;
      this.sort = sort;
      this.questions = data && data.length > 0 ? data : [];
      this.flow = this.exam.flows[sort];
      if (
        this.flow.tagId.indexOf("radio") !== -1 ||
        this.flow.tagId.indexOf("text") !== -1
      ) {
        this.currenttextType = "bishi";
      } else {
        this.currenttextType = "kouyu";
      }

      this.questionAudioNumber = 0;
      this.getPermission();
      // this.recOpen();
      // setTimeout(() => {
      this.handlePlay();
      // }, 200);
    },

    // 听力笔试部分提交
    handleFrontSumbit(flag) {
      if (this.isBtnLoading == true) return;
      this.isBtnLoading = true;
      // console.log("handleFrontSumbit", this.questions, this.sort);
      // return  //待删除
      if (this.status == 7)
        this.$emit("handleFrontSumbit2", this.questions, this.sort);
      else this.$emit("handleFrontSumbit", this.questions, this.sort);
      // this.nextQuestion()
    },
    clearLoading() {
      this.isBtnLoading = false;
    },
    /**刷新**/
    handleRefresh() {
      if (this.status === 1 && this.audioSrc !== "") {
        // this.audioPlayUtil(this.audioSrc);
        const audio = document.getElementById("audio");
        audio.load();
        audio.play();
      }
    },

    handlePlay() {
      this.index++;
      this.audioSrc = "";
      this.rate = 0;
      let urls = this.flow.urls;
      let urlList = [];
      urls.forEach((u) => {
        if (u.src.includes("https")) urlList.push(u);
      });
      console.log(urls);
      this.questionAudioNumber = urlList.length;
      if (this.index < urls.length) {
        let item = urls[this.index];
        this.status = item.type;
        //type:1,播放，2：停顿，3：准备时间，4：录音时长
        if (item.type === 1) {
          this.second = item.value;
          this.audioSrc = item.src;
          this.$refs.audio.src = item.src;
          this.audioPlayUtil(item.src);
          this.currentNumner = urlList.indexOf(item) + 1;
          return;
        }
        if (
          item.type === 2 ||
          item.type === 3 ||
          item.type === 5 ||
          item.type === 6 ||
          item.type === 7 ||
          item.type === 8
        ) {
          let nextItem = urls[this.index + 1];
          if (nextItem && nextItem.type === 1) {
            this.$refs.audio.src = item.src;
          }
          this.second = item.value;
          this.autoNumberUtil();
          return;
        }
        if (item.type === 4 && this.currenttextType === "kouyu") {
          // this.startRecorder()
          this.getPermission();
          this.recOpen();
          // setTimeout(() => {
          //   this.recStart();
          // }, 200);

          return;
        }
      } else {
        console.log(this.index);
        this.status = 4;
        if (this.currenttextType === "bishi") this.handleFrontSumbit(true);
        else this.recStop();
      }
    },

    /**自动播放**/
    audioPlayUtil(src) {
      const self = this;
      // this.$refs.audio.src = src;
      // const audioDom = document.querySelector("#playBtn");
      // audioDom && audioDom.click();
      // this.$refs.audio.click();
      this.$refs.audio.play();
      // this.$refs.audio.muted = ''
      const a = document.getElementById("pross");
      this.$refs.audio.addEventListener("timeupdate", (event) => {
        this.showPop = false;
        self.rate = `${(self.$refs.audio.currentTime * a.scrollWidth) /
          self.$refs.audio.duration}px`;
        self.currentTime = self.$refs.audio.currentTime.toFixed(1);
        self.duration = self.$refs.audio.duration.toFixed(1);
      });
      // this.$refs.audio.addEventListener("play", event => {//播放开始
      //   self.$message.closeAll();
      // });
      this.$refs.audio.addEventListener("ended", (event) => {
        //播放到音频的结束为止，播放停止
        self.currentTime = 0;
        self.duration = 0;
      });
      this.$refs.audio.addEventListener("waiting", (event) => {
        //因为暂时性缺少数据，播放暂停

        timer = setTimeout(() => {
          if (waitingStart) {
            // self.$message.closeAll();
            // self.$message({
            //   message: "网速过慢,音频正在加载中",
            //   type: "warning",
            //   duration: 600000
            // });
            Toast("网速过慢,音频正在加载中");
          }
        }, 3000);
        waitingStart = true;
      });
      this.$refs.audio.addEventListener(
        "playing",
        (event) => {
          //因缺少数据而暂停或延迟的状态结束，播放准备开始
          waitingStart = false;
          clearTimeout(timer);
          timer = null;
          setTimeout(() => {});
          // self.$message.closeAll();
        },
        2000
      );
      // this.$refs.audio.addEventListener('pause',(event)=>{
      //   //console.log('pause---------1')
      // })
    },

    /**跳过**/
    handleSkip() {
      this.second = 1;
      clearInterval(this.Interval);
      this.handlePlay();
    },

    /**倒数计数***/
    autoNumberUtil() {
      clearInterval(this.Interval);
      const that = this;
      this.Interval = setInterval(() => {
        that.second -= 1;
        // console.log(that.status);
        if (that.second == 0) {
          clearInterval(that.Interval);
          if (that.status == 6) this.$emit("preDown");
          if (that.status == 7) {
            that.handleFrontSumbit();
          } else {
            that.handlePlay();
          }
        }
      }, 1000);
    },

    /**
     *  获取麦克风权限
     * */
    getPermission() {
      // Recorder.getPermission().then(
      //   () => {
      //     // this.$message({message: '获取录音权限成功',type: 'success'});
      //   },
      //   (error) => {
      //     // this.$message({ message: "获取录音权限失败", type: "error" });
      //     Toast("获取录音权限失败");
      //   }
      // );
    },

    /**
     *  录音的具体操作功能
     * */
    startRecorder() {
      const self = this;
      recorder.start().then(
        () => {
          self.drawRecord(); //开始绘制图片
          self.drawProgress();
          let speakingSeconds = self.flow.speakingSeconds;
          self.speakingInterval = setInterval(() => {
            speakingSeconds--;
            if (speakingSeconds === 0) {
              clearInterval(self.speakingInterval);
              self.stopRecorder();
            }
          }, 1000);
        },
        (error) => {
          //console.log(`${error.name} : ${error.message}`)
        }
      );
    },

    // tina 重写
    recOpen() {
      //创建录音对象
      this.rec = Recorder2({
        type: "mp3", //录音格式，可以换成wav等其他格式
        sampleRate: 16000, //录音的采样率，越大细节越丰富越细腻
        bitRate: 96, //录音的比特率，越大音质越好
        onProcess: (
          buffers,
          powerLevel,
          bufferDuration,
          bufferSampleRate,
          newBufferIdx,
          asyncEnd
        ) => {
          //录音实时回调，大约1秒调用12次本回调
          //可实时绘制波形，实时上传（发送）数据
          if (this.wave)
            this.wave.input(
              buffers[buffers.length - 1],
              powerLevel,
              bufferSampleRate
            );
        },
      });
      //打开录音，获得权限
      this.rec.open(
        () => {
          // this.$message({ message: '获取录音权限成功!', type: 'success' })
          this.status2 = true;
          if (this.$refs.recwave) {
            //创建音频可视化图形绘制对象
            this.wave = Recorder2.WaveView({ elem: this.$refs.recwave });
          }
          this.recStart();
        },
        (msg, isUserNotAllow) => {
          this.status2 = false;
          //用户拒绝了录音权限，或者浏览器不支持录音
          //console.log((isUserNotAllow ? "UserNotAllow，" : "") + "无法录音:" + msg)
        }
      );
    },

    // 开始录音
    recStart() {
      // this.recOpen()

      setTimeout(() => {
        if (!this.status2) {
          // this.$message({
          //   message: "抱歉，试音未能获取到录音权限",
          //   type: "error"
          // });
          Toast("抱歉，试音未能获取到录音权限");
        } else {
          this.rec.start();
          this.audioMp3Url = "";
          this.drawStatus = 1;
          this.recorderStatus = true;
          // this.drawRecord()//开始绘制图片
          // this.drawProgress()
          ////console.log("已开始录音")
        }
      });
      const self = this;
      recorder.start().then(
        () => {
          self.drawRecord(); //开始绘制图片
          self.drawProgress();
          let speakingSeconds = self.flow.speakingSeconds;
          self.speakingInterval = setInterval(() => {
            speakingSeconds--;
            if (speakingSeconds === 0) {
              clearInterval(self.speakingInterval);
              self.stopRecorder();
            }
          }, 1000);
        },
        (error) => {}
      );
    },

    /**
     * 绘制波浪图-录音
     * */
    drawRecord() {
      this.oCanvas = document.getElementById("canvas");
      this.ctx = this.oCanvas.getContext("2d");
      // 用requestAnimationFrame稳定60fps绘制
      this.drawRecordId = requestAnimationFrame(this.drawRecord);

      // 实时获取音频大小数据
      let dataArray = recorder.getRecordAnalyseData(),
        bufferLength = dataArray.length;
      // 填充背景色
      this.ctx.fillStyle = "rgb(200, 200, 200,.5)";
      this.ctx.fillRect(0, 0, this.oCanvas.width, this.oCanvas.height);

      // 设定波形绘制颜色
      this.ctx.lineWidth = 1;
      this.ctx.strokeStyle = "rgb(0, 0, 0)";
      this.ctx.beginPath();
      let sliceWidth = (this.oCanvas.width * 1.0) / bufferLength, // 一个点占多少位置，共有bufferLength个点要绘制
        x = 0; // 绘制点的x轴位置
      for (let i = 0; i < bufferLength; i++) {
        let v = dataArray[i] / 128.0;
        let y = (v * this.oCanvas.height) / 2;
        if (i === 0) {
          // 第一个点
          this.ctx.moveTo(x, y);
        } else {
          // 剩余的点
          this.ctx.lineTo(x, y);
        }
        // 依次平移，绘制所有点
        x += sliceWidth;
      }
      this.ctx.lineTo(this.oCanvas.width, this.oCanvas.height / 2);
      this.ctx.stroke();
    },

    drawProgress() {
      const self = this;
      let canvas = document.getElementById("line");
      let ctx = canvas.getContext("2d");
      let total = this.flow.speakingSeconds * 10;
      let startX = 0;
      let step = parseFloat((this.oCanvas.width / total).toFixed(2));
      this.canvasInterval = setInterval(() => {
        total--;
        if (total === 0) clearInterval(this.canvasInterval);
        ctx.fillStyle = "red";
        ctx.fillRect(startX, 0, step, this.oCanvas.height);
        startX += step;
      }, 100);
    },

    // 销毁录音
    destroyRecorder() {
      const self = this;
      recorder.destroy().then(function() {
        // recorder = null;
        if (self.drawRecordId === undefined) return;
        self.drawRecordId && cancelAnimationFrame(self.drawRecordId);
        self.drawRecordId = null;
      });
    },

    playRecorder() {
      this.stopRecorder();
      recorder.play();
    },

    /**下一题**/
    nextQuestion() {
      this.status = -1;
      this.sort++;
      clearInterval(this.Interval);
      clearInterval(this.canvasInterval);
      this.destroyRecorder();
      // //console.log(this.sort, 'this.sort --当前')
      // //console.log(this.exam.flows, 'this.exam.flows当前')
      // //console.log(this.questions, 'this.questions 当前')
      this.isBtnLoading = false;
      if (this.sort < this.exam.flows.length) {
        this.init(-1, this.sort, this.questions);
        this.$emit("concatArr", this.questions);
        this.$emit("onClick", this.sort);
      } else {
        if (
          this.questions.length < this.exam.flows.length &&
          this.currenttextType === "bishi"
        ) {
          setTimeout(() => {
            this.$emit("handleStudentTaskSubmit22");
          }, 500);
        } else {
          // //console.log('kkk')
          this.handleStudentTaskSubmit([]);
        }
      }
    },

    /***提交作业**/
    handleStudentTaskSubmit(chuanQuestIons) {
      let param = {
        id: this.exam.id,
        answers: chuanQuestIons.length ? chuanQuestIons : this.questions,
      };
      // const loading = this.$loading({
      //   lock: true,
      //   text: "内容上传中...",
      //   spinner: "el-icon-loading",
      //   background: "rgba(0, 0, 0, 0.7)",
      // });
      this.isShow = true;
      if (this.examType == "1") {
        req.post("/api/exam/studentApi/userTaskSubmit", param).then((res) => {
          this.isShow = false;
          this.submitUtil(res);
        });
      }
      if (this.examType == "3") {
        req.post("/api/exam/studentApi/userTest/submit", param).then((res) => {
          this.isShow = false;
          this.submitUtil(res);
        });
      }
    },

    submitUtil(res) {
      if (res.data.success) {
        this.cancelAudioPlay();
        this.$emit("onClose");
      } else {
        // this.$message({ message: res.errorDesc, type: "error" });
        Toast(res.data.errorDesc);
      }
    },

    // 停止录音
    recStop() {
      if (!this.status) {
        // this.$message({ message: "未打开录音权限", type: "error" });
        Toast("未打开录音权限");
        return;
      }
      if (blobResourse) {
        this.uploadRecording(blobResourse);
        return;
      }
      clearInterval(this.speakingInterval);
      clearInterval(this.canvasInterval);
      clearInterval(this.Interval);
      recorder.stop();

      this.rec.stop(
        (blob, duration) => {
          //blob就是我们要的录音文件对象，可以上传，或者本地播放
          this.recBlob = blob;
          //简单利用URL生成本地文件地址，此地址只能本地使用，比如赋值给audio.src进行播放，赋值给a.href然后a.click()进行下载（a需提供download="xxx.mp3"属性）
          var localUrl = (window.URL || webkitURL).createObjectURL(blob);
          // //console.log("录音成功", blob, localUrl, "时长:" + duration + "ms")

          // this.upload(blob);//把blob文件上传到服务器
          blobResourse = blob;
          this.uploadRecording(blobResourse);

          this.rec.close(); //关闭录音，释放录音资源，当然可以不释放，后面可以连续调用start
          this.rec = null;
        },
        (err) => {
          // console.error("结束录音出错：" + err)
          this.rec.close(); //关闭录音，释放录音资源，当然可以不释放，后面可以连续调用start
          this.rec = null;
        }
      );
    },
    // 上传录音
    uploadRecording(blob) {
      let fileFormData = new FormData();
      fileFormData.append("file", blob);

      let param = {
        businessId: this.exam.id,
        type: 4,
        uploadType: 4,
        attachType: "mp3",
        attachName: `${this.flow.tagId}-口语答案`,
      };

      // const loading = this.$loading({
      //   lock: true,
      //   text: "音频上传中...",
      //   spinner: "el-icon-loading",
      //   background: "rgba(0, 0, 0, 0.7)",
      // });
      this.isShow = true;
      // newUpload({ file: fileFormData, param: param }).then(res => {
      //   `/api/base/upload?${stringify(param)}`
      req
        .post(`/api/base/upload?${stringify(param)}`, fileFormData)
        .then((res) => {
          this.isShow = false;
          if (res.data.success) {
            blobResourse = null;
            const { data } = res.data;
            let param = {
              attachmentId: data.id,
              tagId: this.flow.tagId,
              sort: this.sort,
            };
            this.questions.push(param);
            this.SaveCacheHandler();
            // setTimeout(() => {
            //   // //console.log('下一题222')
            //   this.nextQuestion();
            // }, 1000);
          } else {
            // this.$message({ message: "上传失败", type: "error" });
            Toast("上传失败");
          }
        });
    },

    /**
     * 结束录音,并上传
     * */
    stopRecorder() {
      clearInterval(this.speakingInterval);
      clearInterval(this.canvasInterval);
      recorder.stop();
      this.stopStatus = !this.stopStatus;
      this.recStop();

      // let wav = recorder.getWAVBlob();//获取 WAV 数据
      // const mp3Blob = this.convertToMp3(recorder.getWAV())
      // let fileFormData = new FormData()
      // // fileFormData.append('file',wav);
      // fileFormData.append('file', mp3Blob)
      // let size = fileFormData.size / (1024 * 1024)
      // if (size > 20) {
      //   this.$message({ message: '上传文件不能超过20M!', type: 'error' })
      //   return
      // }
      // this.drawRecordId && cancelAnimationFrame(this.drawRecordId)
      // this.drawRecordId = null
      // let param = {
      //   businessId: this.exam.id,
      //   type: 4,
      //   uploadType: 4,
      //   attachType: 'mp3',
      //   // attachType:'wav',
      //   attachName: `${this.flow.tagId}-口语答案`
      // }
      // const loading = this.$loading({
      //   lock: true,
      //   text: '音频上传中...',
      //   spinner: 'el-icon-loading',
      //   background: 'rgba(0, 0, 0, 0.7)'
      // })
      // newUpload({ file: fileFormData, param: param }).then(res => {
      //   loading.close()
      //   if (res.success) {
      //     const { data } = res
      //     let param = {
      //       attachmentId: data.id,
      //       tagId: this.flow.tagId,
      //     }
      //     this.questions.push(param)
      //     this.SaveCacheHandler()
      //     this.nextQuestion()
      //   } else {
      //     this.$message({ message: '上传失败', type: 'error' })
      //   }
      // })
    },

    /**保存缓存**/
    SaveCacheHandler() {
      let param = { answers: this.questions, id: this.exam.id };
      if (this.examType == "1") {
        req.post("/api/exam/studentApi/saveCache", param).then((res) => {
          this.nextQuestion();
        }); //保存缓存
      }
      if (this.examType == "3") {
        req
          .post("/api/exam/studentApi/userTest/saveCache", param)
          .then((res) => {
            // //console.log('保存位置2')
          }); //保存缓存
      }
    },

    /**
     * 文件格式转换 wav-map3
     * */
    convertToMp3(wavDataView) {
      // 获取wav头信息
      const wav = lamejs.WavHeader.readHeader(wavDataView); // 此处其实可以不用去读wav头信息，毕竟有对应的config配置
      const { channels, sampleRate } = wav;
      const mp3enc = new lamejs.Mp3Encoder(channels, sampleRate, 128);
      // 获取左右通道数据
      const result = recorder.getChannelData();
      const buffer = [];

      const leftData =
        result.left &&
        new Int16Array(result.left.buffer, 0, result.left.byteLength / 2);
      const rightData =
        result.right &&
        new Int16Array(result.right.buffer, 0, result.right.byteLength / 2);
      const remaining = leftData.length + (rightData ? rightData.length : 0);

      const maxSamples = 1152;
      for (let i = 0; i < remaining; i += maxSamples) {
        const left = leftData.subarray(i, i + maxSamples);
        let right = null;
        let mp3buf = null;

        if (channels === 2) {
          right = rightData.subarray(i, i + maxSamples);
          mp3buf = mp3enc.encodeBuffer(left, right);
        } else {
          mp3buf = mp3enc.encodeBuffer(left);
        }

        if (mp3buf.length > 0) {
          buffer.push(mp3buf);
        }
      }
      const enc = mp3enc.flush();
      if (enc.length > 0) {
        buffer.push(enc);
      }
      return new Blob(buffer, { type: "audio/mp3" }); //audio/wav
    },

    // 清除定时器
    tocanceldingshiqi() {
      clearInterval(this.speakingInterval);
      clearInterval(this.Interval);
      clearInterval(this.canvasInterval);
      if (recorder) recorder.stop();
    },

    cancelAudioPlay() {
      this.second = 0;
      this.questions = [];
      this.audioSrc = "";
      recorder.stop();
      this.destroyRecorder();
      clearInterval(this.Interval);
      clearInterval(this.canvasInterval);
      clearInterval(this.speakingInterval);
      this.$refs.audio.pause();
      this.destroyRecorder();
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.bottom {
  width: 100%;
  height: 60px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
  .flex-layout {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .label {
    font-size: 22px;
    font-weight: 500;
    line-height: 32px;
    text-align: center;
  }
  .one {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .two {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .three {
    align-items: center;
    .right-recorder {
      height: 46px;
      padding: 1px;
      border-radius: 2px;
      border: 1px dashed #666666;
      .second-div {
        width: 200px;
        margin: 0 2px;
        position: relative;
        box-sizing: border-box;
        background-color: white;
        transform: translateY(-17px);
        #canvas {
          position: absolute;
          top: 0;
          left: 0;
          height: 38px;
          width: 200px;
        }
        #line {
          position: absolute;
          left: 0;
          bottom: 0;
          height: 4px;
          width: 200px;
        }
      }
    }
  }
  .five {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .pross {
    height: 4px;
    width: 100%;
    position: absolute;
    margin: 0;
    top: -10px;
    .p-span {
      background-color: #00a0e9;
      display: inline-block;
      height: 4px;
    }
  }
}
.toptip {
  font-size: 18px;
}
</style>
