import axios from "axios"
import { mapQuery } from "./URLUtils";
const noTokenUrl = ['jsapiSignature']
// 当返回下面的错误码时直接报错
const query = mapQuery()
const headers = {
    Authorization: "Bearer " + query.token,
};
const request = axios.create({
    // baseURL: 'https://some-domain.com/api/',
    timeout: 60000,
})

request.interceptors.request.use(function (config) {
    if (config.url.indexOf(noTokenUrl) == -1) {
        config.headers = { ...config.headers, ...headers }
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});

request.interceptors.response.use(function (response) {
    if (response.status != 200) {
        alert('服务器异常')
    } else {
        const errorCode = response.data.errorCode
        const errorDesc = response.data.errorDesc
        if (errorCode) {
            if (errorCode == '100001') {
                alert('登录过期')
                wx.miniProgram.redirectTo({
                    url: '/pages/index/index',
                })
            } else {
                if(errorCode !== '900088'){
                    alert(errorDesc)
                    wx.miniProgram.redirectTo({
                        url: '/pages/index/index',
                    })
                }
            }
        }
    }
    return response;
}, function (error) {
    return Promise.reject(error);
});

export default request;
