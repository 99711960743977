import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import req from './util/req'
import { mapQuery } from './util/URLUtils'
import { ImagePreview, ActionSheet, CountDown, Button, Dialog, Toast, Icon } from 'vant';

// import './util/flaxibleRemFontSize'
import './util/remv2'
import 'minireset.css'

// import Vconsole from "vconsole"
// const vConsole = new Vconsole()

Vue.config.productionTip = false

Vue.use(ImagePreview);
Vue.use(ActionSheet);
Vue.use(CountDown);
Vue.use(Button);
Vue.use(Dialog);
Vue.use(Toast);
Vue.use(Icon);

// req.post('/api/auth/appApi/jsapiSignature', {
//   url: window.location.href
// }).then(res => {
//   const data = res.data.data
//   wx.config({
//     debug: true,
//     appId: data.appId, // 必填，公众号的唯一标识
//     timestamp: data.timestamp, // 必填，生成签名的时间戳
//     nonceStr: data.nonceStr, // 必填，生成签名的随机串
//     signature: data.signature,// 必填，签名
//     jsApiList: ['startRecord', 'stopRecord', 'onVoiceRecordEnd'] // 必填，需要使用的JS接口列表
//   });
//   wx.ready(function () {
//     console.log('wx ready')
//   });
// })

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
