<template>
  <div class="homework-page" id="homework-page">
    <BeginTask v-if="data.status == '0'" @onBegin="onBegin" :data="data" />
    <div
      class="do-homework"
      v-if="data.status == '1' || data.status == '0'"
      v-show="data.status == '1'"
    >
      <Countdown
        @onFinish="onFinish"
        v-if="data.timeLimitSeconds && data.status == '1'"
        status="countdown"
        :time="data.timeLeft * 1000"
      />
      <div class="task-container">
        <div
          :class="`task-detail ${data.paperType == '5' ? 'pb1' : ''}`"
          :style="
            data.paperType == '5' ? `height: ${96 - answerSheetVH}vh` : ''
          "
          id="task-detail"
        >
          <div class="task-content" v-html="data.content"></div>
          <AnswerSheet
            v-if="data.paperType == '5'"
            @submit="onSubmit"
            :answerSheetVH="answerSheetVH"
            :data="data"
          />
        </div>
        <div class="btn-container" v-if="data.paperType != '5'">
          <van-button
            class="paizhao-btn"
            style="margin: 0 10px"
            @click="onPhoto"
            round
            type="danger"
            block
            v-if="
              data.paperType == '7' ||
                (data.type == '3' &&
                  data.paperFormat &&
                  data.paperFormat !== '')
            "
            >拍照
            <!-- <van-icon
              class="tip-icon"
              @click.stop="showTip = true"
              name="warning"
            /> -->
          </van-button>
          <van-button
            style="margin: 0 10px"
            @click="onSubmit"
            round
            type="primary"
            block
            v-if="query.paperDetail !== '1'"
            >完成练习</van-button
          >
        </div>
      </div>
    </div>
    <!-- <div class="do-homework"> -->
    <div class="do-homework" v-if="data.status == '4' && result.id">
      <div class="task-container">
        <TaskEvaluation
          id="task-result"
          :data="data"
          :isStudent="isStudent"
          :result="result"
        />
        <div v-if="urlList.length > 0">
          <p style="margin: 20px 0 0 0">原图：</p>
          <img
            style="margin-top: 5px;"
            v-for="item in urlList"
            :key="item"
            :src="item"
            alt=""
          />
        </div>
      </div>
    </div>
    <van-popup
      class="tip-popup"
      v-model="showTip"
      closeable
      :close-on-click-overlay="false"
    >
      <!-- <p class="dcsp-tip">
      1.请同学避免书写空行，可以在不会的题目下打上x<br />
      2.避免写单词的时候出现上下两行的出现，可能会造成错位的情况<br />
      3.对于写错的单词，请在涂改时全部涂掉<br />
      4.上传图片时，避免出现非单词以外的文字，若出现可重拍或裁剪掉无关内容<br />
      5.您的姓名请请写在最上方 6.一个单词或词汇不能换行书写<br />
      7.尽量保持一行一个单词或词组，在书写是尽量注意席号和单词的混潜，如1和L，13和B、9和g尽量保持书写的规范<br />
      8.序号和字母之间，单词和单词之间尽量避免空隙过大
    </p> -->
      <!-- <div class="img-box"> -->
      <img class="tip-img" src="@/assets/dcsp-tip.png" alt="" />
      <!-- </div> -->
    </van-popup>
  </div>
</template>

<script>
import Vue from "vue";
import req from "../util/req";
import { mapQuery } from "../util/URLUtils";
import insert from "../util/insertDOM";
import Countdown from "../components/Countdown";
import BeginTask from "../components/BeginTask";
import AnswerSheet from "../components/AnswerSheet";
import TaskEvaluation from "../components/TaskEvaluation";
import { Dialog, Toast, ImagePreview, Popup } from "vant";
import bluePlayImg from "../assets/icon-1_14.png";
import grayPlayImg from "../assets/icon-1_27.png";
import pauseImg from "../assets/pause.png";
Vue.use(Popup);
export default {
  name: "Home",
  data() {
    return {
      showTip: false,
      urlList: [],
      isStudent: false,
      location: window.location,
      query: mapQuery(),
      hasEmpty: true,
      data: {},
      result: {},
      answers: [],
      time: 0,
      detailDOM: null,
      audioIndex: 0, // 听力题只允许前面的听完再放后面的
      notPlaying: true, // 听力题一次只允许一个在播放
      audioTagList: null,
      audioBtnList: null,
      radioInputList: null,
      checkboxInputList: null,
      textInputList: null,
      textareaInputList: null,
      answerSheetVH: 50,
      resourceShow: false,

      pList: null,
    };
  },
  created() {
    this.isStudent = this.query.isStudent == true;
    this.answers = localStorage.getItem("answer" + this.query.id)
      ? JSON.parse(localStorage.getItem("answer" + this.query.id))
      : [];
    if (this.query.paperDetail) this.getPaperDetail();
    else this.getData(true);
  },
  mounted() {},
  methods: {
    postMessage() {
      wx.miniProgram.postMessage({ data: { foo: "bar" } });
    },

    getFormDataFromDOM() {
      let hasEmpty = false;
      // 单选题多个input同一个id，这里用对象储存，避免重复记录
      const radioAnswers = {};
      const checkAnswers = {};
      const answers = [];
      // 获取所有选择题填写答案
      for (let i = 0; i < this.radioInputList.length; i++) {
        const item = this.radioInputList[i];
        const text = item.value;
        const tagId = item.attributes.name.value;
        radioAnswers[tagId] = radioAnswers[tagId] || "";
        if (item.checked) {
          radioAnswers[tagId] = text.slice(0, 1);
        }
      }
      for (const key in radioAnswers) {
        if (!radioAnswers[key]) {
          hasEmpty = true;
        }
        answers.push({
          tagId: key,
          text: radioAnswers[key],
        });
      }

      // 获取所有多选题填写答案
      for (let i = 0; i < this.checkboxInputList.length; i++) {
        const item = this.checkboxInputList[i];
        const text = item.value;
        const tagId = item.attributes.name.value;
        checkAnswers[tagId] = checkAnswers[tagId] || "";
        if (item.checked) {
          checkAnswers[tagId] += text.slice(0, 1);
        }
      }
      for (const key in checkAnswers) {
        if (!checkAnswers[key]) {
          hasEmpty = true;
        }
        answers.push({
          tagId: key,
          text: checkAnswers[key],
        });
      }

      // 获取所有填空题填写答案
      for (let i = 0; i < this.textInputList.length; i++) {
        const item = this.textInputList[i];
        const text = item.value;
        const tagId = item.attributes.name.value;
        if (!text) {
          hasEmpty = true;
        }
        answers.push({
          tagId,
          text,
        });
      }

      // 获取所有作文题填写答案
      for (let i = 0; i < this.textareaInputList.length; i++) {
        const item = this.textareaInputList[i];
        const text = item.value;
        const tagId = item.attributes.name.value;
        if (!text) {
          hasEmpty = true;
        }
        answers.push({
          tagId,
          text,
        });
      }
      localStorage.setItem("answer" + this.query.id, JSON.stringify(answers));
      this.answers = answers;

      this.hasEmpty = hasEmpty;
      this.SaveCacheHandler();
    },

    /**保存缓存**/
    SaveCacheHandler() {
      let param = { answers: this.answers, id: this.query.id };
      req.post("/api/exam/studentApi/saveCache", param).then((res) => {
        const errorCode = res.data.errorCode;
        const errorDesc = res.data.errorDesc;
        if (errorCode == "100405") {
          Dialog.confirm({
            title: "提示",
            message: "当前用户信息已失效，请重新登录!",
            allowHtml: true,
          }).then(() => {
            wx.miniProgram.redirectTo({
              url: "/pages/select-server/index",
            });
          });
        }
      });
    },

    /**获取缓存**/
    getStudentTesrLoadCache() {
      req
        .post("/api/exam/studentApi/loadCache", { id: this.query.id })
        .then((res) => {
          const { data } = res.data;
          if (data && data.length > 0) {
            this.answers = data;
            this.answersToInput(data);
          }
        });
    },
    // 把缓存的答案填回表单
    answersToInput(answers) {
      for (let i = 0; i < answers.length; i++) {
        const item = answers[i];
        const dom = document.querySelectorAll('[name="' + item.tagId + '"]');
        if (dom.length > 1) {
          for (let j = 0; j < dom.length; j++) {
            if (item.text && item.text.indexOf(dom[j].value) > -1) {
              // if (item.text && dom[j].value.indexOf(item.text) > -1) {
              dom[j].checked = true;
            }
          }
        } else if (dom.length == 1) {
          dom[0].value = item.text;
        }
      }
    },
    getResult() {
      req
        .post(
          this.query.api && this.query.api == "exam"
            ? "/api/exam/userTaskResult"
            : "/api/exam/appApi/userTaskResult",
          {
            id: this.query.id,
          }
        )
        .then((res) => {
          res.data.data.averageScore = Math.ceil(res.data.data.averageScore);
          this.result = res.data.data;
          this.urlList = this.result.url.split("|");
          if (this.data.paperType === "8") {
            this.wakongHandle();
          }
        });
    },
    getData(init) {
      req
        .post(
          this.query.api && this.query.api == "exam"
            ? "/api/exam/userTaskDetail"
            : "/api/exam/appApi/userTaskDetail",
          {
            id: this.query.id,
          }
        )
        .then((res) => {
          const data = res.data.data || {};
          this.data = data;
          const that = this;
          document.title = data.taskTitle;
          // 状态（0：待做 1：进行中 2：待上传 3：待批改 4：已批改 5：过期）
          if (data.status == "0" || data.status == "5") {
            // 重置成待做状态后，清除缓存的答案
            this.data.status = "0";
            localStorage.setItem("answer" + this.query.id, "[]");
            this.answers = [];
          } else if (data.status == "2") {
            Toast("作业已到时间限制，正在上传已填写的作业...");
            this.doSubmitReq(true, false);
            return;
          } else if (data.status == "3") {
            Toast("试卷批改中，正在返回作业列表...");
            setTimeout(() => {
              wx.miniProgram.redirectTo({
                url: "/pages/common-index/index",
              });
            }, 1000);
            return;
          }
          // else if (data.status == "5") {
          //   Toast("试卷已过期，正在返回首页...");
          //   setTimeout(() => {
          //     wx.miniProgram.redirectTo({
          //       url: "/pages/common-index/index",
          //     });
          //   }, 1000);
          //   return;
          // }
          if (data.status == "4") {
            this.getResult();
          }
          if (data.content && init) {
            if (data.paperFormat && data.paperFormat === 1)
              data.content = insert.getPaperContent(data);
            setTimeout(() => {
              const detailDOM = document.querySelector("#task-detail");
              const imgDomList = document.querySelectorAll("#task-detail img");
              const imgList = [];
              if (imgDomList && imgDomList.length) {
                for (let i = 0; i < imgDomList.length; i++) {
                  imgList.push(imgDomList[i].src);
                }
                for (let i = 0; i < imgDomList.length; i++) {
                  const item = imgDomList[i];
                  item.addEventListener("click", (e) => {
                    const src = e.target.src;
                    ImagePreview({
                      images: imgList,
                      closeable: true,
                      startPosition: i,
                    });
                    // console.log("imgDomList click", e.target.src);
                  });
                }
              }

              // 插入播放按钮
              const audioTagList = document.querySelectorAll("audio");
              if (audioTagList && audioTagList.length > 0) {
                for (let i = 0; i < audioTagList.length; i++) {
                  const newNode = document.createElement("div");
                  newNode.className = "play-btn-container";
                  newNode.id = "audio" + i;
                  const parent = audioTagList[i].parentNode;
                  // newNode.innerHTML = `
                  //   <svg viewBox="0 0 1060 1060"><path d="M 530 530 m 0, -500 a 500, 500 0 1, 1 0, 1000 a 500, 500 0 1, 1 0, -1000" class="van-circle__hover" style="fill: none; stroke: rgb(255, 255, 255); stroke-width: 60px;"></path><path d="M 530 530 m 0, -500 a 500, 500 0 1, 1 0, 1000 a 500, 500 0 1, 1 0, -1000" stroke="#ff1717" class="van-circle__layer" style="fill: none; stroke: rgb(255, 23, 23); stroke-width: 61px; stroke-dasharray: 0px, 3140px;"></path></svg>
                  //   <img src="${i == 0 ? bluePlayImg : grayPlayImg}" />
                  // `;
                  newNode.innerHTML = `
                    <svg viewBox="0 0 1060 1060"><path d="M 530 530 m 0, -500 a 500, 500 0 1, 1 0, 1000 a 500, 500 0 1, 1 0, -1000" class="van-circle__hover" style="fill: none; stroke: rgb(255, 255, 255); stroke-width: 60px;"></path><path d="M 530 530 m 0, -500 a 500, 500 0 1, 1 0, 1000 a 500, 500 0 1, 1 0, -1000" stroke="#ff1717" class="van-circle__layer" style="fill: none; stroke: rgb(255, 23, 23); stroke-width: 61px; stroke-dasharray: 0px, 3140px;"></path></svg>
                    <img src="${bluePlayImg}" /><span style="position: absolute;bottom:-20px;display: inline-block;width: 120px">听力音频${i +
                    1}</span>
                  `;
                  //<button id="btn${i}" style="margin-left: 1.5rem;margin-top: 0.2rem">跳过</button>
                  // parent.insertBefore(newNode, audioTagList[i]);
                  parent.replaceChild(newNode, audioTagList[i]);
                  //跳过的逻辑
                  // parent.addEventListener("click", function(event) {
                  //   if (event.target.tagName === "BUTTON") {
                  //     event.stopPropagation();
                  //     let i = event.target.id.substring(3, 4)-0;
                  //     audioTagList[i].pause()
                  //     audioTagList[i].currentTime = 0;
                  //     that.notPlaying = true;
                  //     that.audioIndex =
                  //       that.audioIndex > i + 1 ? that.audioIndex : i + 1;
                  //     that.audioBtnList[i].children[0].style.display = "none";
                  //     that.audioBtnList[i].children[1].src = bluePlayImg;
                  //     for (let j = 0; j < that.audioBtnList.length; j++) {
                  //       that.audioBtnList[j].children[1].src = bluePlayImg;
                  //     }
                  //   }
                  // });
                  audioTagList[i].addEventListener("ended", (event) => {
                    that.notPlaying = true;
                    that.audioIndex =
                      that.audioIndex > i + 1 ? that.audioIndex : i + 1;
                    that.audioBtnList[i].children[0].style.display = "none";
                    that.audioBtnList[i].children[1].src = bluePlayImg;
                    for (let j = 0; j < that.audioBtnList.length; j++) {
                      that.audioBtnList[j].children[1].src = bluePlayImg;
                    }
                    // for (let j = 0; j <= i; j++) {
                    //   that.audioBtnList[j].children[1].src = bluePlayImg;
                    // }
                    // if (that.audioBtnList[i + 1]) {
                    //   that.audioBtnList[i + 1].children[1].src = bluePlayImg;
                    // }
                  });
                  audioTagList[i].addEventListener("timeupdate", (event) => {
                    const result =
                      (that.audioTagList[i].currentTime /
                        that.audioTagList[i].duration) *
                      3140;
                    that.audioBtnList[
                      i
                    ].children[0].children[1].style.strokeDasharray =
                      result + "" + "px 3140px";
                  });
                  audioTagList[i].addEventListener("play", (event) => {
                    that.audioBtnList[i].children[0].style.display = "block";
                    for (let j = 0; j < that.audioBtnList.length; j++) {
                      if (i == j)
                        that.audioBtnList[j].children[1].src = grayPlayImg;
                    }
                  });
                  audioTagList[i].addEventListener("pause", (event) => {
                    console.log(2323);
                    that.notPlaying = true;
                    that.audioBtnList[
                      that.audioIndex
                    ].children[1].src = pauseImg;
                  });
                }
              }
              that.audioTagList = audioTagList;
              setTimeout(() => {
                const audioBtnList = document.querySelectorAll(
                  ".play-btn-container"
                );
                for (let i = 0; i < audioBtnList.length; i++) {
                  if (audioBtnList[i]) {
                    audioBtnList[i].children[1].style.display = "block";
                    audioBtnList[i].addEventListener("click", (e) => {
                      const item = e.currentTarget;
                      // 当前点击的第几个audio
                      const index = Number(item.id.slice(5));
                      if (that.audioIndex == index) {
                        if (that.notPlaying) {
                          that.notPlaying = false;
                          that.audioTagList[index].play();
                        } else {
                          that.audioTagList[that.audioIndex].pause();
                          that.audioBtnList[
                            that.audioIndex
                          ].children[1].src = pauseImg;
                          that.notPlaying = true;
                        }
                      }
                    });
                  }
                }
                that.audioBtnList = audioBtnList;
              }, 0);
              that.detailDOM = detailDOM;
              if (!detailDOM) {
                return;
              }
              let cardFlag = false;
              that.pList = document.querySelectorAll("p");
              for (let i = 0; i < that.pList.length; i++) {
                let tag = that.pList[i];
                tag.style.textAlign = "left";
                if (tag.innerText.includes("A.B.C.")) {
                  tag.style.marginBottom = "28px";
                  cardFlag = true;
                }
              }

              that.radioInputList = document.querySelectorAll(
                'input[type="radio"]'
              );
              if (cardFlag) {
                for (let i = 0; i < that.radioInputList.length; i++) {
                  let tag = that.radioInputList[i];
                  tag.style.width = "24px";
                  // if (!tag.value.includes("A")) {
                  //   tag.style.marginLeft = "20px";
                  // }
                  if ("EFGHIJKMNOPQRSTUVWXYZ".includes(tag.value)) {
                    tag.style.marginTop = "10px";
                    tag.style.marginBottom = "28px";
                  }
                }
              }

              that.checkboxInputList = document.querySelectorAll(
                'input[type="checkbox"]'
              );
              if (cardFlag) {
                for (let i = 0; i < that.checkboxInputList.length; i++) {
                  let tag = that.checkboxInputList[i];
                  tag.style.width = "24px";
                  tag.style.marginLeft = "20px";
                }
              }

              that.textInputList = document.querySelectorAll(
                'input[type="text"]'
              );
              that.textareaInputList = document.querySelectorAll("textarea");
              // 首次初始化页面时绑定事件 监听输入变化 重复执行此函数结果不变
              detailDOM.addEventListener("click", (e) => {
                that.getFormDataFromDOM();
              });
              // detailDOM.addEventListener("touchend", () => {
              //   console.log("touchend");
              //   that.getFormDataFromDOM();
              // });
              const clientWidth =
                (document.body.clientWidth ||
                  document.getElementsByTagName("html")[0].offsetWidth) * 0.92;
              for (let i = 0; i < that.textInputList.length; i++) {
                that.textInputList[i].addEventListener("input", (e) => {
                  that.getFormDataFromDOM();
                  let width = Math.max(that.textInputList[i].scrollWidth, 80);
                  width = Math.min(width, clientWidth);
                  that.textInputList[i].style.width = width + "px";
                });
                setTimeout(() => {
                  let width = Math.max(that.textInputList[i].scrollWidth, 80);
                  width = Math.min(width, clientWidth);
                  that.textInputList[i].style.width = width + "px";
                }, 100);
              }
              for (let i = 0; i < that.textareaInputList.length; i++) {
                that.textareaInputList[i].addEventListener("input", (e) => {
                  that.getFormDataFromDOM();
                  that.textareaInputList[i].style.height =
                    that.textareaInputList[i].scrollHeight + "px";
                });
                setTimeout(() => {
                  that.textareaInputList[i].style.height =
                    that.textareaInputList[i].scrollHeight + "px";
                }, 100);
              }
              if (that.answers && that.answers.length) {
                setTimeout(() => {
                  that.answersToInput(that.answers);
                  that.getFormDataFromDOM();
                }, 0);
              } else {
                this.getStudentTesrLoadCache();
              }
            }, 0);
            if (data.paperType === "8") {
              this.wakongHandle();
            }
          }
        });
    },
    onBegin() {
      req
        .post("/api/exam/appApi/userTaskStart", {
          id: this.query.id,
        })
        .then((res) => {
          const errorCode = res.data.errorCode;
          const errorDesc = res.data.errorDesc;
          if (errorCode == "900052") {
            Dialog.confirm({
              title: "提示",
              message: errorDesc,
              allowHtml: true,
            }).then(() => {
              wx.miniProgram.redirectTo({
                url: "/pages/common-index/index",
              });
            });
          } else if (errorCode) {
            Toast(errorDesc);
          } else {
            this.data = {
              ...this.data,
              ...res.data.data,
              content: this.data.content,
              answerSheet: this.data.answerSheet,
            };
          }
          this.$nextTick(() => {
              const taskContent = document.getElementById("homework-page");
              taskContent.scrollTop = 0;
          });
        });
    },

    onPhoto() {
      // 单选题多个input同一个id，这里用对象储存，避免重复记录
      const radioAnswers = {};
      const checkAnswers = {};
      const answers = [];
      const self = this;
      // 获取所有选择题填写答案
      for (let i = 0; i < this.radioInputList.length; i++) {
        const item = this.radioInputList[i];
        const text = item.value;
        const tagId = item.attributes.name.value;
        radioAnswers[tagId] = radioAnswers[tagId] || "";
        if (item.checked) {
          radioAnswers[tagId] = text.slice(0, 1);
        }
      }
      for (const key in radioAnswers) {
        answers.push(key);
      }

      // 获取所有多选题填写答案
      for (let i = 0; i < this.checkboxInputList.length; i++) {
        const item = this.checkboxInputList[i];
        const text = item.value;
        const tagId = item.attributes.name.value;
        checkAnswers[tagId] = checkAnswers[tagId] || "";
        if (item.checked) {
          checkAnswers[tagId] += text.slice(0, 1);
        }
      }
      for (const key in checkAnswers) {
        answers.push(key);
      }

      // 获取所有填空题填写答案
      for (let i = 0; i < this.textInputList.length; i++) {
        const item = this.textInputList[i];
        const tagId = item.attributes.name.value;
        answers.push(tagId);
      }

      // 获取所有作文题填写答案
      for (let i = 0; i < this.textareaInputList.length; i++) {
        const item = this.textareaInputList[i];
        const tagId = item.attributes.name.value;
        answers.push(tagId);
      }
      let url = `/pages/zizhu/index?taskId=${this.query.id}&answers=${answers}`;
      // if(this.data.paperFormat && this.data.paperFormat !== ''){
      //   url= `/pages/zizhu/index?taskId=${this.query.id}`
      // }
      console.log(url);
      wx.miniProgram.redirectTo({ url });
    },

    onSubmit() {
      if (this.hasEmpty) {
        Dialog.confirm({
          title: "完成练习",
          message:
            '<div class="red">还有未完成的题目，是否仍要提交练习？</div>',
          allowHtml: true,
        }).then(() => {
          this.doSubmitReq(true, true);
        });
      } else {
        Dialog.confirm({
          title: "完成练习",
          message: "是否提交该练习",
          allowHtml: true,
        }).then(() => {
          this.doSubmitReq(true, true);
        });
      }
    },
    doSubmitReq(backToIndex, showToast) {
      req
        .post("/api/exam/appApi/userTaskSubmit", {
          id: this.query.id,
          answers: this.answers.map((answer) => {
            answer.text = answer.text.replace(/(^\s*)|(\s*$)/g, "");
            return answer;
          }),
        })
        .then((res) => {
          const errorCode = res.data.errorCode;
          const errorDesc = res.data.errorDesc;
          if (errorCode == "900088") {
            Dialog.confirm({
              title: "提示",
              message: errorDesc,
              allowHtml: true,
            }).then(() => {});
            return;
          }
          if (errorCode == "900052") {
            Dialog.confirm({
              title: "提示",
              message: errorDesc,
              allowHtml: true,
            }).then(() => {
              wx.miniProgram.redirectTo({
                url: "/pages/common-index/index",
              });
            });
            return;
          }
          if (res.data.success) {
            if (showToast) {
              Toast("作业提交成功！");
            }
            if (backToIndex) {
              wx.miniProgram.redirectTo({
                url: "/pages/common-index/index",
              });
            }
          }
        });
    },
    onFinish() {
      this.doSubmitReq(false, false);
      Dialog.confirm({
        title: "提示",
        message: "时间限制到了，作答结束，已做答案会自动上传",
        showCancelButton: false,
      }).then(() => {
        wx.miniProgram.redirectTo({
          url: "/pages/common-index/index",
        });
        // this.doSubmitReq(true, true);
      });
    },
    getPaperDetail() {
      req
        .post("/api/exam/paperDetail", {
          id: this.query.id,
        })
        .then((res) => {
          const data = res.data.data || {};
          this.data = data;
          const that = this;
          document.title = data.title;
          if (data.content) {
            setTimeout(() => {
              const detailDOM = document.querySelector("#task-detail");
              const imgDomList = document.querySelectorAll("#task-detail img");
              const imgList = [];
              if (imgDomList && imgDomList.length) {
                for (let i = 0; i < imgDomList.length; i++) {
                  imgList.push(imgDomList[i].src);
                }
                for (let i = 0; i < imgDomList.length; i++) {
                  const item = imgDomList[i];
                  item.addEventListener("click", (e) => {
                    const src = e.target.src;
                    ImagePreview({
                      images: imgList,
                      closeable: true,
                      startPosition: i,
                    });
                  });
                }
              }
              // 插入播放按钮
              const audioTagList = document.querySelectorAll("audio");
              if (audioTagList && audioTagList.length > 0) {
                for (let i = 0; i < audioTagList.length; i++) {
                  const newNode = document.createElement("div");
                  newNode.className = "play-btn-container";
                  newNode.id = "audio" + i;
                  const parent = audioTagList[i].parentNode;
                  newNode.innerHTML = `
                    <svg viewBox="0 0 1060 1060"><path d="M 530 530 m 0, -500 a 500, 500 0 1, 1 0, 1000 a 500, 500 0 1, 1 0, -1000" class="van-circle__hover" style="fill: none; stroke: rgb(255, 255, 255); stroke-width: 60px;"></path><path d="M 530 530 m 0, -500 a 500, 500 0 1, 1 0, 1000 a 500, 500 0 1, 1 0, -1000" stroke="#ff1717" class="van-circle__layer" style="fill: none; stroke: rgb(255, 23, 23); stroke-width: 61px; stroke-dasharray: 0px, 3140px;"></path></svg>
                    <img src="${bluePlayImg}" /><span style="position: absolute;bottom:-20px;width: 120px;display:inline-block">听力音频${i +
                    1}</span>
                  `;
                  // parent.insertBefore(newNode, audioTagList[i]);
                  parent.replaceChild(newNode, audioTagList[i]);

                  audioTagList[i].addEventListener("ended", (event) => {
                    that.notPlaying = true;
                    that.audioIndex =
                      that.audioIndex > i + 1 ? that.audioIndex : i + 1;
                    that.audioBtnList[i].children[0].style.display = "none";
                    that.audioBtnList[i].children[1].src = bluePlayImg;
                    for (let j = 0; j < that.audioBtnList.length; j++) {
                      that.audioBtnList[j].children[1].src = bluePlayImg;
                    }
                  });
                  audioTagList[i].addEventListener("timeupdate", (event) => {
                    const result =
                      (that.audioTagList[i].currentTime /
                        that.audioTagList[i].duration) *
                      3140;
                    that.audioBtnList[
                      i
                    ].children[0].children[1].style.strokeDasharray =
                      result + "" + "px 3140px";
                  });
                  audioTagList[i].addEventListener("play", (event) => {
                    that.audioBtnList[i].children[0].style.display = "block";
                    for (let j = 0; j < that.audioBtnList.length; j++) {
                      that.audioBtnList[j].children[1].src = grayPlayImg;
                    }
                  });
                }
              }
              that.audioTagList = audioTagList;
              setTimeout(() => {
                const audioBtnList = document.querySelectorAll(
                  ".play-btn-container"
                );
                for (let i = 0; i < audioBtnList.length; i++) {
                  if (audioBtnList[i]) {
                    audioBtnList[i].children[1].style.display = "block";
                    audioBtnList[i].addEventListener("click", (e) => {
                      const item = e.currentTarget;
                      // 当前点击的第几个audio
                      const index = Number(item.id.slice(5));
                      if (that.notPlaying) {
                        // if (that.audioIndex >= index && that.notPlaying) {
                        that.notPlaying = false;
                        that.audioTagList[index].play();
                      }
                    });
                  }
                }
                that.audioBtnList = audioBtnList;
              }, 0);
              that.detailDOM = detailDOM;
              if (!detailDOM) {
                return;
              }
              that.radioInputList = document.querySelectorAll(
                'input[type="radio"]'
              );

              that.checkboxInputList = document.querySelectorAll(
                'input[type="checkbox"]'
              );
              that.textInputList = document.querySelectorAll(
                'input[type="text"]'
              );
              that.textareaInputList = document.querySelectorAll("textarea");
              // 首次初始化页面时绑定事件 监听输入变化 重复执行此函数结果不变
              detailDOM.addEventListener("click", (e) => {
                that.getFormDataFromDOM();
              });
              const clientWidth =
                (document.body.clientWidth ||
                  document.getElementsByTagName("html")[0].offsetWidth) * 0.92;
              for (let i = 0; i < that.textInputList.length; i++) {
                that.textInputList[i].addEventListener("input", (e) => {
                  that.getFormDataFromDOM();
                  let width = Math.max(that.textInputList[i].scrollWidth, 80);
                  width = Math.min(width, clientWidth);
                  that.textInputList[i].style.width = width + "px";
                });
                setTimeout(() => {
                  let width = Math.max(that.textInputList[i].scrollWidth, 80);
                  width = Math.min(width, clientWidth);
                  that.textInputList[i].style.width = width + "px";
                }, 100);
              }
              for (let i = 0; i < that.textareaInputList.length; i++) {
                that.textareaInputList[i].addEventListener("input", (e) => {
                  that.getFormDataFromDOM();
                  that.textareaInputList[i].style.height =
                    that.textareaInputList[i].scrollHeight + "px";
                });
                setTimeout(() => {
                  that.textareaInputList[i].style.height =
                    that.textareaInputList[i].scrollHeight + "px";
                }, 100);
              }
              if (that.answers && that.answers.length) {
                setTimeout(() => {
                  that.answersToInput(that.answers);
                  that.getFormDataFromDOM();
                }, 0);
              } else {
                this.getStudentTesrLoadCache();
              }
            }, 0);
          }
        });
    },

    wakongHandle() {
      //挖空题
      setTimeout(() => {
        const data = this.data;
        const inputList = document.querySelectorAll("input");
        let mapTagId = {};
        if (data.tagIds && data.tagIds !== "") {
          let mt = data.tagIds.split(",");
          if (mt && mt.length > 0) {
            mt.forEach((m) => {
              if (mapTagId[m] === undefined) mapTagId[m] = m;
            });
          }
        }
        let mapTaskFlow = {};
        if (data.taskFlowList && data.taskFlowList.length > 0) {
          data.taskFlowList.forEach((m) => {
            if (mapTaskFlow[m.tagId] === undefined) mapTaskFlow[m.tagId] = m;
          });
        }
        for (let i = 0; i < inputList.length; i++) {
          let q = inputList[i];
          const parent = q.parentNode;
          if (mapTagId[q.name] === undefined) {
            const newNode = document.createElement("span");
            const answer =
              mapTaskFlow[q.name] !== undefined
                ? mapTaskFlow[q.name].answer
                : "";
            newNode.innerHTML = `${answer}`;
            parent.replaceChild(newNode, q);
          }
        }
      }, 0);
    },
  },
  components: {
    Countdown,
    BeginTask,
    TaskEvaluation,
    AnswerSheet,
  },
};
</script>

<style lang="less">
.homework-page {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
}
.task-container {
  padding: 0 4% 0.4rem;
  width: 100%;
  box-sizing: border-box;
  .MsoNormal {
    font-size: medium !important;
  }
  .task-content {
    white-space: normal;
    word-break: keep-all;
    width: 100%;
    /*overflow-x: auto;*/
    -webkit-overflow-scrolling: touch;
    /*overflow-y: scroll;*/
    & > * {
      // width: 100% !important;
      // overflow-x: auto;
      & input {
        overflow-x: auto;
        /*width: 80px;*/
        /*max-width: 80px;*/
      }
    }
  }
  .van-action-sheet {
    min-height: 20%;
    width: 92%;
    left: 4%;
    bottom: 4%;
    box-shadow: 0 -1px 10px rgba(0, 0, 0, 0.2);
    border-radius: 0.1rem;
  }
  .van-action-sheet__content {
    height: 100%;
  }
}
.task-detail {
  padding-bottom: 0.3rem;
  overflow-y: auto;
  font-family: "Times New Roman";
  font-size: 10.5pt;
  // font-size: 0.42rem;
  line-height: 1.6em;
  width: 100%;
}

.MsoNormal {
  font-size: 0.42rem;
}

.btn-container {
  padding: 0.3rem;
  display: flex;
  justify-content: space-around;
}
/*.exam-content>>>p{*/
/*  white-space: normal;*/
/*  word-break:keep-all;*/
/*}*/
/*.exam-content>>>span{*/
/*  white-space: normal;*/
/*  word-break:keep-all;*/
/*}*/
.paizhao-btn {
  position: relative;
  .tip-icon {
    position: absolute;
    right: -11px;
    top: -11px;
    font-size: 20px;
    color: #ffba00;
    padding: 5px;
  }
}
.dcsp-tip {
  width: 80vw;
  line-height: 1.5;
  padding: 40px 20px;
}
.img-box {
  width: 90vw;
  height: 90vh;
  overflow-y: auto;
}
.tip-img {
  width: auto;
  height: auto;
  max-width: 90vw;
  max-height: 90vh;
  object-fit: contain;
}

.tip-popup.van-popup {
  background-color: rgba(255, 255, 255, 0);
  .van-popup__close-icon {
    color: #000;
  }
}
</style>
