// 因为作业题使用html片段 所以需要以插入dom的形式插入分数
const getScoreColor = function (score, total) {
    const persent = Math.floor(score / total * 100)
    if (persent > 89) {
        return "green"
    }
    if (persent > 59) {
        return "yellow"
    }
    return "red"
}

// count 1时一行一个 2时一行两个
const scoreCell = function (args) {
    const count = args.count || 1
    const newNode = document.createElement('div')
    const score0 = Math.ceil(args.score[0])
    const score1 = Math.ceil(args.score[1])
    newNode.className += 'row'
    if (count == 1) {
        newNode.innerHTML = `
        <div class="score-cell">
            <div class="label">${args.label[0]}</div>
            <div class="score">
                <span class="text ${getScoreColor(score0, args.total[0])}">${score0}</span>
                <span class="total">/${args.total[0]}</span>
            </div>
        </div>
        `
    } else if (count == 2) {
        newNode.innerHTML = `
        <div class="col-49">
            <div class="score-cell">
                <div class="label">${args.label[0]}</div>
                <div class="score">
                    <span class="text ${getScoreColor(score0, args.total[0])}">${score0}</span>
                    <span class="total">/${args.total[0]}</span>
                </div>
            </div>
        </div>
        <div class="col-49">
            <div class="score-cell">
                <div class="label">${args.label[1]}</div>
                <div class="score">
                    <span class="text ${getScoreColor(score1, args.total[1])}">${score1}</span>
                    <span class="total">/${args.total[1]}</span>
                </div>
            </div>
        </div>
      `
    }
    return newNode
}

const evaluationCell = function (args) {
    const newNode = document.createElement('div')
    newNode.className += 'row'
    const title = args.title || '评语'
    const comment = args.comment || ''
    newNode.innerHTML = `
    <div class="comment-cell">
        <div class="comment-title">${title}</div>
        <div class="comment-content">${comment}</div>
    </div>
        `
    return newNode
}


/**排序**/
export function arraySort(property){
    return function (a,b) {
        return a[property]-b[property];
    }
}

const getPaperContent = function (data) {
    let content = '';
    let flows= data.flows && data.flows.length?data.flows.sort(arraySort('sort')):data.taskFlowList.sort(arraySort('sort'));
    flows.forEach((r,index)=>{
        if(index === 0){
            content += `<table style="border-collapse: collapse; width: 100%; height: ${flows.length*.5}rem;" border="1">
                        <tbody>
                         <tr style="height: 21px;">
                            <td style="height: .5rem;text-align: center;padding: 0 .1rem">序号</td>
                            <td style="height: .5rem;text-align: center;padding: 0 .1rem">&nbsp;内容</td>
                            <td style="height: .5rem;text-align: center;padding: 0 .1rem">&nbsp;答案</td>
                         </tr>`;
        }
        content += `<tr style="height: 21px;">
                          <td style="height:.5rem;text-align: center;padding: 0 .1rem">${r.sort}</td>
                          <td style="height: .5rem;padding: 0 .1rem">${r.note}</td>
                          <td style="height: .5rem;padding: 0 .1rem">
                           <input type="text" name="${r.tagId}" style="width: 70px; border-width: 0px 0px 1px; border-bottom-style: solid;">
</td>
                       </tr>`
        if(index === flows.length-1){
            content += '</tbody></table>'
        }
    })
    return content;
}
export default {
    getScoreColor,
    scoreCell,
    evaluationCell,
    getPaperContent
}
