


/**
 * 将 uri 的查询字符串参数映射成对象
 *
 * @method mapQuery
 * @memberOf string
 *
 * @param {String} uri 要映射的 uri
 * @return {Object} 按照 uri 映射成的对象
 *
 * @example
 *  var url = "http://tms.qihang.com/?hello=4765078&style=blue";
 *  // queryObj 则得到一个{hello:"4765078", style:"blue"}的对象。
 *  var queryObj = mapQuery(url);
 * };
 */
export function mapQuery(uri) {
    //window.location.search
    // uri= 'https://gateway.jeedu.net/pen?id=914a7513abab40789080ca6c1b8a8a96&paperDetail=1&token=eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySW5mbyI6eyJpZCI6ImQxOWRiYjIyNzIyMDQ1Y2U5MjMwZDE2MDUwNDhhYjg0IiwidXNlcm5hbWUiOiLmiLTogIHluIgiLCJwYXNzd29yZCI6bnVsbCwibmFtZSI6IuaItOiAgeW4iCIsInR5cGUiOiIyIiwic2Nob29sSWQiOiJlMWZjMGJlNzIzMzM0ODQ2YmU1Y2RlZjZhNTMwMWNiZCIsImRiS2V5IjoiMDAwMiJ9LCJ1c2VyX25hbWUiOiLmiLTogIHluIh8ZTFmYzBiZTcyMzMzNDg0NmJlNWNkZWY2YTUzMDFjYmQiLCJzdWNjZXNzIjp0cnVlLCJzY29wZSI6WyJyZWFkIiwid3JpdGUiXSwiZXhwIjoxNjI3NDUwOTMzLCJhdXRob3JpdGllcyI6WyJST0xFX0FETUlOIl0sImp0aSI6Ijg5M2QwZTAyLWM2NTctNDY4ZS1hZDFiLWYyYmU1NjQzY2FmMiIsImNsaWVudF9pZCI6ImZ5bGwifQ.DSVkdpoQ9fjMcV63I_x_IJm_-vJYo6WZNb9v9pjw3lU-SI_ajU1P9yFv1K5gG1i2Bioz-mBC0IE2g-SDw8kS2IRBgG6PvgD-ScVEzW9sTorDO9H4vZiOIHYoU3tIFQ79mkPhhHOol5AvMW-9-XsApkzPzUZAkhzOWxHLaddmUAiGDldwNgQ1LDlOTv65rI7Q33F3zq4RHgCwVizwinzQp57mDQv1Kwxt3Krm9F_dFX6exOprvogNPczQHD_G6tBtAO33PRggpD6Iy78IzEBgVSexZNdCkH_K-r7LpBndHMRDV04RXWKUKfNYNPDabTrAjuq5gXGbkLVsDWr2RHcuhg'
    // uri =  'http://localhost:8080/pen?id=8de1bbefe15b41cf81c489588392dfd2&token=eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySW5mbyI6eyJpZCI6IjFmMWNlMzM2MTMyZTQ0MzQ4YTAwNTIwNTk2OWU3MmFhIiwidXNlcm5hbWUiOiLliJjpnZnlrpwiLCJwYXNzd29yZCI6bnVsbCwibmFtZSI6IuWImOmdmeWunCIsInR5cGUiOiIzIiwic2Nob29sSWQiOiIwMDE2IiwiZGJLZXkiOiIwMDE2IiwiZXhwaXJ5RGF0ZSI6bnVsbCwib3JnSWQiOm51bGx9LCJ1c2VyX25hbWUiOiLliJjpnZnlrpx8MDAxNiIsInN1Y2Nlc3MiOnRydWUsInNjb3BlIjpbInJlYWQiLCJ3cml0ZSJdLCJleHAiOjE2NjgyOTc2NzIsImF1dGhvcml0aWVzIjpbIlJPTEVfQURNSU4iXSwianRpIjoiYjgyZWE1ZGQtOWM4Yy00ZmYyLTkyM2YtNmIzMGJlNDZhODUxIiwiY2xpZW50X2lkIjoiZnlsbCJ9.je6F_jgKClDNcV2eNIXYpz9AaBDBlfPiWxQlzcGt-BdBcTu-RCScOtGuNNoQZRppxZUWdPKrImF_aoDfINWNXXzKI-H0kJ5cyxl2M1FpeQzzqG9YhFl78yF8Jle-neAUox0duJh0wBd3oa9tV3UCvfrK7vO65yVYpoBzy52Kam1N4I4VGxW7UjyippQ_xrh8lLVp0hfG7bj3PhMFy0DkXNIV3G49HvMSD2xDwk9H4Jy_X5kv40iCHTEDXCHJJiaKz4Dt1aHBGcNE0vJXg0WhczZXPhEoQ9tTjwOENAqfVDdBb3SY6sLU1GeJj0fpCEeql1-3cwoJXAGEAEQGdngfmQ'
    uri = uri && uri.split('#')[0] || window.location.search; //remove hash
    var i,
        key,
        value,
        index = uri.indexOf("?"),
        pieces = uri.substring(index + 1).split("&"),
        params = {};
    if (index === -1) {//如果连?号都没有,直接返回,不再进行处理.
        return params;
    }
    for (i = 0; i < pieces.length; i++) {
        try {
            index = pieces[i].indexOf("=");
            key = pieces[i].substring(0, index);
            value = pieces[i].substring(index + 1);
            if (!(params[key] = (value))) {
                throw new Error("uri has wrong query string when run mapQuery.");
            }
        }
        catch (e) {
            console.log("错误：[" + e.name + "] " + e.message + ", " + e.fileName + ", 行号:" + e.lineNumber + "; stack:" + typeof e.stack, 2);
        }
    }
    // console.log(params,'params')
    return params;
}
